<template>
  <div class="data-feed">
    <div class="view1 art1" v-waypoint="{active: !aniMap.show, callback: (e) => cb(e, 0),  options}">
      <b-container>
        <transition enter-active-class="animated delay-500 fadeInUp">
          <div class="text-center tit" v-show="aniMap.show">
            <b-img :src="require('../../assets/images/feeds/t1.png')"/>
          </div>
        </transition>
        <transition enter-active-class="animated delay-500 fadeInUp">
          <div class="sub-text1" v-show="aniMap.show">Oracle is now live. Data feeds will update every 3 minutes.</div>
        </transition>
        <transition-group enter-active-class="animated delay-500 fadeInUp" tag="b-row">
          <b-col lg="3" cols="12" v-for="(item, i) in list" v-bind:key="i" v-show="aniMap.show"
                 class="d-none d-md-block">
            <div class="view2">
              <div class="view2-text1 d-flex align-items-center">
                {{ item.symbol.toUpperCase() }} / USD

                <b-img class="img" width="36" height="36" :src="item.image"/>

              </div>
              <div class="view2-text2" v-if="gotData">$ {{ item.current_price }}</div>
              <div class="view2-text2" v-else>
                <img class="view2-img2" src="../../assets/images/loading.gif"/>
              </div>
              <div class="view2-text3">Source of feeds:</div>
              <div class="view2-text5">
                <img class="view2-img1" src="../../assets/images/feeds/df_2.png"/>
                <img class="view2-img1" src="../../assets/images/feeds/df_3.png"/>
                <!-- <img class="view2-img1" src="../assets/images/feeds/df_4.png" /> -->
                <img class="view2-img1" src="../../assets/images/feeds/df_5.png"/>
                <img class="view2-img1" src="../../assets/images/feeds/df_6.png"/>
                <!-- <img class="view2-img1" src="../assets/images/feeds/df_7.png" /> -->
              </div>
            </div>
          </b-col>
          <b-col lg="3" cols="12" :key="'last'" v-show="aniMap.show" class="d-none d-md-flex">
            <div class="view2">
              <div class="view2-text4">
                more feeds are coming<br/>...
              </div>
            </div>
          </b-col>
        </transition-group>
        <transition enter-active-class="animated delay-500 fadeInUp">
          <div class="list d-block d-md-none" v-show="aniMap.show">
            <div class="list-item" v-for="(item, i) in list" v-bind:key="i">
              <div class="l1">
                <div class="i1">
                  <div class="img-box">
                    <b-img width="36" height="36" :src="item.image"/>
                  </div>
                  <span>{{ item.symbol.toUpperCase() }} / USD</span>
                </div>
                <div class="i2">$ {{ item.current_price }}</div>
              </div>
              <div class="l2">
                <div class="i1">
                  <span class="point"></span>
                  <span class="txt">Source of feeds</span>
                </div>
                <div class="i2">
                  <b-img class="view2-img1" width="30" height="30"
                         :src="require('../../assets/images/feeds/df_2.png')"/>
                  <b-img class="view2-img1" width="30" height="30"
                         :src="require('../../assets/images/feeds/df_3.png')"/>
                  <b-img class="view2-img1" width="30" height="30"
                         :src="require('../../assets/images/feeds/df_5.png')"/>
                  <b-img class="view2-img1" width="30" height="30"
                         :src="require('../../assets/images/feeds/df_6.png')"/>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </b-container>
    </div>
    <div class="art2" v-waypoint="{active: !aniMap.show1, callback: (e) => cb(e, 1),  options}">
      <b-container>
        <transition enter-active-class="animated delay-500 fadeInUp">
          <div class="text3" v-show="aniMap.show1">
            <div class="text-center tit">
              <b-img :src="require('../../assets/images/feeds/t2.png')"/>
            </div>
            <div class="sub-tit">Oracle is now live. Data feeds will update every 3 mintes</div>
          </div>
        </transition>
        <transition enter-active-class="animated delay-500 zoomIn">
          <div class="text-center" v-show="aniMap.show1">
            <b-img :src="require('../../assets/df_1.png')" fluid/>
          </div>
        </transition>

        <b-row class="action">
          <transition enter-active-class="animated delay-1000 fadeInLeft">
            <b-col class="text-right" v-show="aniMap.show1">
              <div class="button mr-10" onclick="window.open('https://docs.berrydata.co/integration/how-to-using');">
                Integrate with Berry Data
                <b-img width="25" :src="require('../../assets/images/home/arrow.png')"></b-img>
              </div>
            </b-col>
          </transition>
          <transition enter-active-class="animated delay-1000 fadeInRight">
            <b-col class="text-left" v-show="aniMap.show1">
              <div class="button ml-10"
                   onclick="window.open('https://docs.berrydata.co/integration/sample-use-berry');">
                Sample Project Using Berry
                <b-img width="25" :src="require('../../assets/images/home/arrow.png')"></b-img>
              </div>
            </b-col>
          </transition>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: "data-feed",
  components: {},
  data: () => ({
    list: [],
    intervalId: null,
    gotData: false,
    options: {
      root: null,
      rootMargin: '0px 0px 0px 0px',
      threshold: [0, 1]
    },
    aniMap: {
      show: false,
      show1: false,
    }
  }),
  methods: {
    cb({going, direction}, idx) {
      if (direction === 'bottom') return;
      if (this.$waypointMap.GOING_IN === going) {
        this.aniMap[`show${idx ? idx : ''}`] = true
      } else {
        this.aniMap[`show${idx ? idx : ''}`] = false

      }
    },
    updatePrice(data) {
      for (var i = 0; i < data.length; ++i) {
        var item = data[i];
        if (item.length === 0) continue;
        this.list[i].price = (Number(item[0][1]) / 1000000).toFixed(2);
      }
    },
    async fetchData() {
      try {
        let ret = await axios.get('https://yield.berrydata.co/prices')
        this.list = ret.data;
        this.gotData = true;
      } catch (e) {
        this.gotData = false;
        console.dir(e);
      }
    }
  },
  created() {
    this.fetchData();
    if (this.intervalId != null) return;
    this.intervalId = setInterval(() => {
      this.fetchData();
    }, 30000);
  },
  destroyed() {
    clearInterval(this.intervalId);
    this.intervalId = null;
  }

}
</script>

<style lang="less">
.data-feed {
  background: #FFFAFF;
  overflow: hidden;

  .action {
    color: #a58aff;
    text-align: center;
    font-size: 18px;
    margin-top: 85px;
  }

  .art2 {
    //height: 920px;
    background-color: #4D3C64;
    padding: 80px 0 100px;

    .sub-tit {
      margin-bottom: 60px;
      font-size: 16px;
      margin-top: 20px;
    }

    .button {
      padding: 0 30px;
      height: 47px;
      border-radius: 47px;
      font-size: 14px;
      font-weight: bold;
      border: 1px solid #F7F2F6;
      background-color: #F7F2F6;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      color: #880D85;
      cursor: pointer;
      position: relative;
      z-index: 1;

      img {
        margin-left: 20px;
      }
    }
  }

  .view1 {
    background-size: cover;
    padding-top: 50px;

    .row {
      margin-right: -25px;
      margin-left: -25px;
    }

    .col-sm-6, .col-lg-4 {
      padding-right: 25px;
      padding-left: 25px
    }
  }

  .text1 {
    text-align: center;
    line-height: 1;
    font-size: 60px;
    font-weight: bold;
    color: #333;
  }

  .sub-text1 {
    text-align: center;
    line-height: 1;
    font-size: 16px;
    // font-weight: bold;
    color: #3C103A;
    margin-top: 10px;
    margin-bottom: 60px;
  }

  .view2 {
    height: 276px;
    padding: 30px;
    background: #FFFAFF;
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 10px #C695BC;
    border-radius: 18px;

    .view2-text1 {
      font-size: 20px;
      font-weight: bold;
      line-height: 1;
      color: #3C103A;
      width: 100%;
      position: relative;
      align-self: flex-start;

      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: -14px;
        width: 10px;
        height: 10px;
        transform: translateY(-50%);
        background-color: #3C103A;
        border-radius: 50%;
      }

      .img {
        margin-left: auto;
      }
    }

    .view2-text4 {
      font-size: 18px;
      font-weight: bold;
      line-height: 25px;
      color: #3C103A;
      text-align: center;
    }

    .view2-text2 {
      font-size: 38px;
      font-weight: bold;
      color: #880D85;
      margin-top: 34px;
      text-align: center;
    }

    .view2-text3 {
      font-size: 16px;
      font-weight: bold;
      color: #3C103A;
      line-height: 1;
    }

    .view2-text5 {
      overflow: hidden;
      margin-top: 30px;
    }

    .view2-img1 {
      float: left;
      width: 37px;
      height: 37px;
      border-radius: 50%;
      object-fit: contain;
      margin-right: 14px;

      &:last-child {
        margin-right: 0;
      }
    }

    .view2-img2 {
      float: left;
      width: 33px;
      height: 33px;
      object-fit: contain;
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }

  }

  .text3 {
    font-size: 60px;
    color: #333333;
    margin: 0px auto 30px;
    max-width: 772px;
    text-align: center;
    line-height: 1;

    .sub-tit {
      font-size: 16px;
      margin-top: 20px;
      color: #fff;
    }
  }

  .gray-btn {
    cursor: pointer;
  }


}

@media (max-width: 768px) {
  .data-feed {
    .art1 {
      padding: 45px 10px;

      .tit {
        width: 337px;
        margin: 0 auto;

        img {
          width: 100%;
        }
      }

      .sub-text1 {
        width: 167px;
        margin: 5px auto 30px;
        font-size: 12px;
        line-height: 1.5;
        //margin-bottom: 30px;
      }

      .view2 {
        margin-bottom: 20px;
      }

      .col-12:last-of-type .view2 {
        margin-bottom: 70px;
      }

    }

    .art2 {
      padding-top: 70px;
      height: auto;
      padding-bottom: 50px;

      .tit {
        width: 240px;
        margin: 0 auto;

        img {
          width: 100%;
        }
      }

      .sub-tit {
        width: 167px;
        margin: 10px auto 40px;
        font-size: 12px;
        line-height: 1.5;
        //margin-bottom: 30px;
      }

      .action {
        margin-top: 40px;

        .col {
          text-align: center !important;
        }

        .button {
          margin: 0 !important;
          width: 266px;
          margin-bottom: 25px !important;
          padding: 0;
          font-weight: bold;
        }
      }
    }

    .list {
      &-item {
        padding: 30px 20px;
        background-color: #FFFAFF;
        box-shadow: 0 0 10px #c695bc;
        border-radius: 14px;
        font-weight: bold;
        margin-bottom: 20px;

        &:last-of-type {
          margin-bottom: 0;
        }

        .l1, .l2 {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        .i1 {
          display: inline-flex;
          align-items: center;
        }

        .l1 {
          margin-bottom: 15px;

          .i1 {
            span {
              margin-left: 3px;
              font-size: 18px;
              color: #3C103A;
            }
          }

          .i2 {
            color: #880D85;
            font-size: 22px;
          }
        }

        .l2 {
          .i1 {
            .point {
              width: 8px;
              height: 8px;
              border-radius: 50%;
              background-color: #880D85;
            }

            .txt {
              color: #3C103A;
              font-size: 14px;
              margin-left: 4px;
            }
          }

          .i2 {
            img {
              border-radius: 50%;
              margin-right: 4px;

              &:last-of-type {
                margin-right: 0;
              }
            }
          }
        }
      }
    }
  }
}
</style>
